/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductAttributeValue {
    &-SubLabel {
        font-weight: 400;
        font-size: 12px;
        white-space: break-spaces;
        padding-inline: 6px;
        margin-inline-start: 16px;
        background: $neutral-color-6;
        border-radius: 1px;
        color: $font-color-1;
    }
    &-Text {
        label {
            &:first-of-type {
                  .input-control {
                    border-color: $secondary-color-3;
                }
            }
        }
    }
}

