/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .ProductWishlistButton {
    width: unset;
    height: unset;
    
    &-Button {
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: unset;
        background: $primary-color-1;
        border-radius: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        &_likeLink {
            background: transparent;
            width: auto;
            height: auto;
            min-width: unset;
            border-radius: unset;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: $secondary-color-1;

            svg {
                margin-right: 9px;
                
                path {
                  stroke: $secondary-color-1;
                }
            }
        }
    }
}
