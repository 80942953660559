/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductAvailabilityList {
    overflow-x: auto;
    width: 100%;

    @include mobile {
        max-width: calc(100vw - 32px);

        &::-webkit-scrollbar {
            height: 0;
            width: 0;
        }
    }

    &-Table {
        width: 100%;

        button {
            align-items: center;
            margin-right: 10px;

            path {
                stroke: red;
            }
        }

        td {
            font-size: 16px;
        }

        td,
        th {
            overflow-wrap: break-word;
            vertical-align: middle;

            &:nth-of-type(3) {
                width: 120px;
            }

            &:last-of-type {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 330px;
            }

            @include mobile {
                width: 240px;
            }
        }

        .Button {
            height: 38px;
            min-height: 38px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}