/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .ProductPrice {
    display: block;

    &-PriceValueLabel,
    &-SubPriceLabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-2;
    }

    &-PriceValue {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-1;
    }

    &-SubPrice {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $font-color-1;
    }

    &-PriceCustomDiscount.ProductPrice-Price {
        > .ProductPrice-PriceValue {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-CustomPrice {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-color-3;
    }
}

[dir="ltr"] .ProductPrice_hasDiscount .ProductPrice-PriceCustomDiscount.ProductPrice-Price > .ProductPrice-PriceValue {
    color: $font-color-1;
}
