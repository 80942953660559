/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductList {
    &.ProductListWidget .ProductList-MoreHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 57px;

        h2 {
            margin-top: 0;
        }

        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: $secondary-color-1;
            text-decoration: none;
            text-transform: uppercase;
            display: flex;

            &::after {
                content: url('src/assets/icons/arrow-right.svg');
                display: inline-flex;
                margin-left: 3px;
                align-self: center;
            }
        }
    }


    &-Slider {
        padding: 0;

        @include mobile {
            padding: 0 14px;
        }

        .ProductCard {
            width: auto;
            max-width: unset;
        }

        .slick-list {
            @include desktop {
                padding: 10px;
                margin: -10px;
                z-index: 2;
                overflow: visible;
                overflow-x: clip;
            }
        }

        .slick-slide {
            max-width: 230px !important;

            @include mobile {
                padding-left: 6px;
                padding-right: 6px;
            }
        }
    }

    &.ProductListWidget {
        padding-bottom: 0;

        h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 42px;
        }
    }

    @include mobile {
        .Pagination {
            margin-top: 0;
        }
    }

    .slick-track {
        padding: 10px 0 80px;

        @include mobile {
            padding-block: 16px 40px;
        }
    }
}