/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductAttributes {
    &-Content {
        @include desktop {
            padding-top: 33px;
        }
    }

    &-Attributes {
        grid-template-columns: auto 1fr;
        row-gap: 4px;

        @include mobile {
            grid-template-columns: repeat(2, 50%);
        }
    }

    &-AttributeLabel {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        color: $font-color-2;
        font-size: 16px;
        font-weight: 400;
        padding: 4px 10px;

        @include desktop {
            min-width: 160px;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &-ExpandableContentContent {
        margin-top: 0;

        &_isContentExpanded {
            @include mobile {
                margin-bottom: 16px;
            }
        }
    }

    &-ValueLabel {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        color: $font-color-2;
        font-size: 16px;
        font-weight: 600;
        padding: 4px 0;

        @include mobile {
            font-size: 14px;
        }
    }

    &-ValueLabel,
    &-AttributeLabel {
        border-bottom: 0;

        &:nth-of-type(even) {
            background-color: $neutral-color-8;
        }
    }
}