/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductTabs {
    border-bottom: none;
    margin-bottom: 15px;
    position: relative;

    &::before {
        background: $secondary-color-7;
        border-radius: 66px;
        bottom: -9px;
        box-shadow: inset 1px 1px 4px rgba(0, 30, 74, 0.15);
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
    }

    &-Wrapper {
        margin-bottom: 60px;

        @include mobile {
            margin-bottom: 40px;

            &:last-child {
                border-bottom: 1px solid $neutral-color-13;
            }

            svg {
                path {
                    stroke: $secondary-color-1;
                }
            }
        }

        .ExpandableContent {
            border-color: $neutral-color-13;

            @include mobile {
                &:first-child {
                    border-top: none;
                }
            }

            &-Heading {
                font-size: 18px;
                font-weight: 600;
                text-transform: none;
                color: $font-color-1;
    
                @include mobile {
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            &-Button {
                @include mobile {
                    padding-block: 16px;
                }
            }
        }
    }
}