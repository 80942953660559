/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductTab {
    &-Button {
        font-size: 18px;
        font-weight: 600;
        padding: 8px 1.5px;
        text-transform: lowercase;
        color: $font-color-1;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &-Item {
        display: flex;
        justify-content: center;
        width: 160px;

        &_isActive {
            background-color: inherit;
            color: $secondary-color-1;
            position: relative;

            &::after {
                background: $secondary-color-1;
                border-radius: 66px;
                bottom: -9px;
                box-shadow: inset 1px 1px 4px rgba(0, 30, 74, 0.15);
                content: '';
                height: 5px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }
    }
}