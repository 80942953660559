/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

[dir="ltr"] .ProductActions-Qty.Field {
    margin-right: 20px;
}

.ProductActions {
    @include mobile {
        margin-bottom: 42px;
    }

    &-AddToCartFixed {
        align-items: center;

        .hideOnScroll & {
            transform: translateY(0);
        }
    }

    &-AddToCart {
        max-width: 300px;

        @include mobile {
            &Fixed {
                .hideOnScroll & {
                    transform: translateY(0);
                }

                .Field {
                    &-Wrapper {
                        align-items: center;
                    }
                }

                .ProductWishlistButton {
                    margin-left: 14px !important;

                    &-Button {
                        background: none;
                        border: 2px $secondary-color-1 solid;
                        border-radius: 2px;
                        height: 48px;
                        width: 48px;

                        svg {
                            path {
                                stroke: $secondary-color-1;
                            }
                        }
                    }
                }

                &_isIos {
                    @include mobile {
                        bottom: 40px;
                    }
                }
            }
        }

        &Wrapper {
            margin-top: 14px;

            @include mobile {
                margin-top: 0;
            }

            &_outOfStock {
                .AddToCart {
                    margin-right: 0 !important;
                    max-width: 239px;
                }

                .ProductActions {
                    &-AddToProductAvailabilityListButton {
                        margin-right: 20px;
                        padding: 14px 30px;
                    }
                }
            }
        }
    }

    &-AdditionalActions {
        display: flex;
        margin-left: 16px;
        margin-top: 34px;

        @include mobile {
            flex-direction: column;
            margin-top: 16px;
        }

        .AskAboutProduct {
            margin-right: 40px;

            @include mobile {
                margin-bottom: 14px;
            }
        }

        .ProductWishlistButton {
            @include mobile {
                margin-bottom: 30px;
            }

            &-Button {
                color: $secondary-color-3;

                path {
                    stroke: $neutral-color-3 !important;
                }
            }
        }
    }

    &-Attribute {
        color: $font-color-2;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include mobile {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-Attributes {
        li {
            margin-bottom: 4px;

            &:last-of-type {
                margin-bottom: 14px;
            }

            &::before {
                display: none;
            }

            strong {
                color: $font-color-2;
                font-weight: 600;
                margin-left: 10px;
            }
        }

        @include mobile {
            margin-bottom: 16px;
        }
    }

    &-Availability {
        color: $font-color-2;
        display: flex;
        font-size: 16px;

        @include mobile {
            font-size: 14px;
        }

        .AvailabilityStatus {
            margin-left: 8px;

            &-Qty {
                color: $font-color-2;
                font-weight: 600;

                &_isUnavailable {
                    color: $status-red-dark;
                    font-weight: 400;
                }
            }
        }
    }

    &-Discount {
        align-items: center;
        border: 4px $secondary-color-4 solid;
        border-radius: 80px;
        background: $secondary-color-1;
        display: flex;
        flex-direction: column;
        height: 80px;
        justify-content: center;
        width: 80px;

        >span {
            color: $white;
            font-weight: 700;

            &:nth-of-type(1) {
                font-size: 14px;
                line-height: 18px;
            }

            &:nth-of-type(2) {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

    &-GrossPrice {
        margin-right: 27px;

        &::after {
            background: $disabled-color;
            border-radius: 4px;
            content: '';
            height: 100%;
            position: absolute;
            right: -15px;
            width: 3px;
        }

        &Section {
            display: flex;
            margin-bottom: 14px;

            @include mobile {
                margin-bottom: 8px;
            }

            span,
            strong {
                color: $font-color-2;
                font-size: 18px;
                line-height: 24px;
            }

            strong {
                font-weight: 600;
                margin-left: 4px;
                font-weight: 600;

                @include mobile {
                    color: $font-color-1;
                }
            }
        }
    }

    &-GuestPriceSection {
        color: $font-color-2;
        font-size: 16px;
        margin-bottom: 14px;
        margin-top: 46px;

        @include mobile {
            font-size: 14px;
            margin-bottom: 16px;
            margin-top: 2px;
        }

        div {
            &:first-of-type {
                margin-bottom: 6px;
            }
        }

        strong {
            font-weight: 600;
            margin-left: 4px;
        }
    }

    &-LoginButton {
        margin-bottom: 60px;

        @include mobile {
            margin-bottom: 0;
            padding: 0;
        }
    }

    &-LogisticMinimumWrapper {
        align-items: center;
        background-color: $primary-color-6;
        border: 1px $secondary-color-1 solid;
        border-radius: 1px;
        display: inline-flex;
        font-size: 16px;
        justify-content: center;
        line-height: 24px;
        padding: 10px 22px;

        strong {
            margin-left: 6px;
        }

        svg {
            margin-right: 6px;
        }
    }

    &-MainPrice {
        align-items: center;
        display: flex;
        margin-bottom: 14px;

        @include mobile {
            margin-bottom: 8px;
        }
    }

    &-NettoPrice {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        @include mobile {
            margin-left: 10px;
        }

        span {
            &:nth-of-type(1) {
                font-size: 18px;
                line-height: 24px;

                @include mobile {
                    line-height: 20px;
                }
            }

            &:nth-of-type(2) {
                color: $secondary-color-3;
                font-size: 36px;
                font-weight: 600;
                line-height: 42px;
            }
        }
    }

    &-PriceSection {
        margin-bottom: 14px;
        margin-top: 14px;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    &-ProductStatus {
        align-items: center;
        background: $orange-color-1;
        border-radius: 24px;
        display: flex;
        font-size: 18px;
        font-weight: 600;
        min-height: 30px;
        width: fit-content;
        padding: 0 12px 0 26px;
        margin-right: 24px;
        margin-bottom: 15px;

        @include mobile {
            font-size: 16px;
            margin-bottom: 5px;
        }

        &::before {
            background: $status-orange-dark;
            border-radius: 10px;
            content: '';
            height: 10px;
            left: 8px;
            position: absolute;
            width: 10px;
            margin-top: 3px;
        }
    }

    &-ProductWithoutReturnSection {
        align-items: center;
        border: 2px solid $secondary-color-5;
        color: $font-color-2;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        justify-content: center;
        line-height: 24px;
        margin-bottom: 14px;
        margin-left: 16px;
        margin-top: 14px;
        max-width: 492px;
        min-height: 65px;

        @include mobile {
            margin-top: 16px;
        }

        svg {
            background: $white;
            left: 0;
            outline: 4px solid $white;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    &-Qty {
        border-bottom: 2px solid $neutral-color-5;
        border-radius: 4px;
        border-top: 2px solid $neutral-color-5;
        cursor: default;
        display: flex;
        height: 48px;
        margin-block-start: 0;

        @include mobile {
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            height: 32px;
            margin-right: 14px !important;
        }

        input {
            min-width: 92px;
            width: 0; // for Firefox

            @include mobile {
                background-color: transparent;
                min-width: 32px;
                width: 32px;
            }
        }

        button {
            @include mobile {
                height: 32px !important;
                width: 32px !important;
            }

            &:not([disabled]) {
                border: 2px $secondary-color-1 solid;
                border-radius: 4px;
                cursor: pointer;
            }

            &:disabled {
                border-color: $disabled-color;
                border-radius: 4px;
                opacity: 1 !important;

                svg {
                    path {
                        fill: $disabled-color;
                    }
                }
            }
        }
    }

    &-StatusInformation {
        background-color: $orange-color-1;
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 16px;
        padding: 14px 20px;
        border: 1px solid $yellow-status-color;
        border-radius: 4px;
        margin-bottom: 12px;
        margin-top: 20px;
        font-weight: 400;
        width: 64.7%;

        @include desktop {
            align-items: center;
        }

        @include mobile {
            margin-top: 0px;
            width: 100%;
        }

        div {
            margin-left: 8px;
        }
    }

    &-SuggestedPrice {
        font-size: 16px;
        line-height: 24px;

        @include mobile {
            font-size: 14px;
        }

        p {
            margin-bottom: 0;
        }

        span {
            color: $font-color-2;

            &:last-of-type {
                font-weight: 600;
                margin-left: 4px;
            }
        }
    }

    &-Title {
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 14px;
        margin-top: 10px;

        @include mobile {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 16px;
            margin-top: 16px;
        }
    }
}
