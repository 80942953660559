/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductPage {
    @include desktop {
        &-ExpandableContentContent {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 0;
        }

        &-ExpandableContentHeading {
            display: none;
        }

        &-Wrapper {
            column-gap: 20px;
            grid-template-columns: 555px 1fr;
        }
    }

    @include mobile {
        &-ExpandableContentContent {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        padding-bottom: 0;
        margin-bottom: calc(var(--footer-total-height) - 20px);
    }

    .ProductLinks {
        margin-bottom: 90px;

        &-Title {
            font-weight: 600;
            margin-bottom: 20px;
            padding: 0;
        }

        &:last-of-type {
            margin-bottom: 80px;
        }

        @include mobile {
            margin-bottom: 40px;

            &-List {
                grid-template-columns: repeat(2, 1fr);
            }

            &-Title {
                font-size: 24px;
                margin-bottom: 10px;
                padding: 0;
            }
        }
    }

    &-LogisticMinimumWrapper {
        align-items: center;
        background-color: $primary-color-6;
        border: 1px $secondary-color-1 solid;
        border-radius: 1px;
        display: none;
        font-size: 16px;
        justify-content: center;
        line-height: 24px;
        padding: 10px 22px;

        @include mobile {
            display: inline-flex;
            font-size: 14px;
            margin-bottom: 16px;
            padding: 8px;

            @media (max-width: 390px) {
                font-size: 13px;
            }

            @media (max-width: 366px) {
                font-size: 12px;
            }
        }

        strong {
            margin-left: 6px;
        }

        svg {
            margin-right: 6px;

            @include mobile {
                height: 20px;
                width: 20px;
            }
        }
    }

    &-PromotionBlock {
        @include desktop {
            margin: 0 auto;
            max-width: var(--content-wrapper-width);
            margin-bottom: 60px;
        }
    }
}