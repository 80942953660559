/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CartProductStatusInformation {
    &-InfoWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }

    &-StatusInformation {
        display: flex;
        align-items: center;
        background-color: #ffe6e6;
        color: #cc0000;
        padding: 10px 15px;
        border: 1px solid #cc0000;
        border-radius: 4px;
        font-size: 14px;

        &--isZeroPriceProduct,
        &--isZeroPriceProductInWishlist {
            border-color: #cc0000;
            background-color: #ffe6e6;

            svg {
                path {
                    stroke: #cc0000;
                }
            }

            div {
                color: #cc0000;
            }
        }

        div {
            margin-left: 10px;
        }
    }
}
